import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material'
import { navigate } from 'gatsby-link'
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Add,
  AddCircleOutline,
  Apartment,
  Home,
  Person
} from '@mui/icons-material'

import Loader from '../common/Loader'
import { routePaths } from '../../constants/routes'
import { useProfileContext } from '../../contexts/profileContext'
import { useAppContext } from '../../contexts/appContext'
import { SubContainer } from '../common/Styled'
import DeleteDialog from './DeleteDialog'
import { Address } from '../../helpers/types'
import Header from '../common/Header'

import { AddressesContainer, EmptyContainer } from './Styled'
import { deleteAddress, getAddresses } from '../../helpers/api/addresses'

const action = {
  icon: <AddCircleOutline />,
  label: 'Ajouter une adresse',
  route: routePaths.address
}

const Addresses = () => {
  const profile = useProfileContext()
  const user = profile?.user
  const [loading, setLoading] = useState(false)
  const [addresses, setAddresses] = useState<Address[]>([])

  const showSnackbar = useAppContext()?.showSnackbar

  useEffect(() => {
    const fetchAddresses = async () => {
      setLoading(true)
      try {
        const result = await getAddresses()
        setAddresses(result)
      } catch (e) {
        showSnackbar('error')
      }
      setLoading(false)
    }
    if (user?.id) fetchAddresses()
  }, [user?.id])

  const [selectedAddress, setSelectedAddress] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = async (id: string) => {
    try {
      await deleteAddress(id)
      setAddresses(prevState =>
        prevState.filter(address => address.id !== parseInt(id, 10))
      )
      showSnackbar('success', "L'adresse a été supprimée")
    } catch (e) {
      console.error(e)
      showSnackbar('error')
    }
  }

  const handleOpen = address => {
    setSelectedAddress(address)
    setIsOpen(true)
  }

  const handleClose = () => setIsOpen(false)

  const title = 'Mes adresses'
  const items = [
    {
      icon: <Home />,
      label: 'Accueil',
      route: routePaths.home
    },
    {
      icon: <Person />,
      label: 'Mon compte',
      route: routePaths.profile
    },
    {
      icon: <Apartment />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <Header {...{ items, action, title }} />
      <AddressesContainer>
        {loading && <Loader />}
        {!loading && isEmpty(addresses) && (
          <EmptyContainer>
            <Typography>
              Vous n&#39;avez pas encore d&#39;adresse enregistrée.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(routePaths.address)}
              startIcon={<Add />}
            >
              Ajouter une adresse
            </Button>
          </EmptyContainer>
        )}
        {!loading &&
          addresses.length > 0 &&
          addresses.map(address => {
            const {
              id,
              firstName,
              lastName,
              address1,
              address2,
              city,
              postalCode,
              country,
              mobilePhone,
              landlinePhone,
              addressName
            } = address
            return (
              <Card
                key={id}
                variant="outlined"
                style={{
                  maxWidth: 'calc(50% - 8px)',
                  minWidth: 250,
                  width: 'calc(50% - 8px)'
                }}
              >
                <CardContent>
                  <Typography variant="h4" component="h2">
                    {addressName}
                  </Typography>
                  <Divider />
                  <Typography>{`${firstName} ${lastName}`}</Typography>
                  <Typography>{address1}</Typography>
                  <Typography>{address2}</Typography>
                  <Typography>{`${postalCode} ${city}`}</Typography>
                  <Typography>{country}</Typography>
                  <Typography>{mobilePhone}</Typography>
                  <Typography>{landlinePhone}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => navigate(`${routePaths.address}?id=${id}`)}
                  >
                    Modifier
                  </Button>
                  <Button onClick={() => handleOpen(address)} size="small">
                    Supprimer
                  </Button>
                </CardActions>
              </Card>
            )
          })}
      </AddressesContainer>
      {isOpen && (
        <DeleteDialog {...{ handleClose, handleDelete, selectedAddress }} />
      )}
    </SubContainer>
  )
}

export default Addresses
