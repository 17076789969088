import { Button, styled } from "@mui/material";

export const AddressesContainer = styled("div")`
  padding: 16px 0;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
`;

export const TitleContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: auto;
  gap: 30px;
`;
