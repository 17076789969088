import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

const DeleteDialog = (props) => {
  const { handleClose, handleDelete, selectedAddress } = props;
  const { id, addressName } = selectedAddress;

  const handleConfirm = () => {
    handleDelete(id);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open>
      <DialogTitle id="simple-dialog-title">{`Voulez-vous supprimer l'adresse "${addressName}" ?`}</DialogTitle>

      <DialogActions style={{ justifyContent: "space-evenly" }}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Annuler
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="error">
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
