import React from "react";

import Addresses from "../components/Addresses";
import SEO from "../components/common/seo";

const AddressesPage = () => (
  <>
    <SEO title="Adresses" />
    <Addresses />
  </>
);

export default AddressesPage;
